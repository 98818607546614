$cta-color: #7c2ae8;
body {
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    padding: 0;
    margin: 0;
    background-color: linear-gradient(rgba(179, 179, 179, 0.247), rgba(255, 255, 255, 0.589));
    background-position: cover;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

.messages {
    padding: 0 10rem;
}

.hero {
    width: 100vw;
    padding: 2rem 10rem 4rem 10rem;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    position: relative;
    &::before, &::after  {
        content: "";
        width: 200%;
        height: 500px;
        background: rgba(42, 74, 193, 0.02);
        position: absolute;
        top: 0;
        z-index: -2;
        left: -50%;
        transform: rotate(-4deg);
    }
    &::after {
        transform: rotate(4deg);
        top: 92px;
    }
   .left {
       flex: 2;
   }
   .right {
       flex: 1;
   }
    h1 {
        font-size: 4em;
        font-weight: 700;
        margin: 0;
    }
    p {
        line-height: 1.5;
        &.strapline {
            font-size: 2rem;
        }
    }
    .ebook {
        background-image: url(../img/javascript_howtos_book_cover.png);
        background-size: cover;
        background-position: center;
        height: 300px;
    }
    .signup-form {
        width: 75%;
        margin: 0 auto;
        align-self: center;
        display: flex;
        flex-direction: column;

        input, button {
            font-size: 1rem;
            padding: 1rem;
            margin: .25rem 0;
        }
        p.disclaimer {
            font-size: .75rem;
            line-height: 1.2;
            margin: .25rem 0;
        }
        .checkbox {
            margin-left: .5rem;
        }

        button {
            color: white;
            background-color: $cta-color;
            font-weight: 700;
            outline: none;
            border: 0;
            transition: all .3s ease;
            cursor: pointer;
            &:disabled {
                background-color: darken($cta-color, 30%);
            }
            &:hover:enabled, &:focus:enabled {
                background-color: darken($cta-color, 10%);
            }
        }
    }
}

.confirmation {
    background-color: #06c5b2;
}
.error {
    background-color: rgb(231, 47, 47);
}
.confirmation, .error {
    color: #fff;
    padding: .5rem 1rem;
    margin-top: -4rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
    &.hidden { display: none; }
}
footer {
    text-align: center;
}

@media screen and (max-width: 660px) {
    .hero {
        padding: .5rem;
        flex-direction: column;
        &::before  {
            top: -20%;
            height: 1200px;
        }
        &::before, &::after {
            width: 100%;
        }
        .ebook {
            height: 400px;
        }
    }
}